import { waitAuth } from "~/utils/middleware";

import { Sub } from "~/types/subscriptions";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async () => {
	await waitAuth(Sub.Type.Partner);

	const isCanceled = __subscription().getIsDisabled(Sub.Type.Partner);
	if (!__subscription().has(Sub.Type.Partner) && !isCanceled) {
		log("❌ Has no Partner");
		return navigateTo("/");
	}

	log("✅ Has Partner or Partner is canceled");
});
